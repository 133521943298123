import React, { useState, useContext, useEffect, FC } from 'react';
import { useMutation } from '@apollo/client'
import { AuthContext } from '../lib/authContext';
import { useRouter } from 'next/router'
import { LOGIN_USER } from '../lib/queries';
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { setToCurrentVersion } from '../lib/version';

function FormErrors({ errors }: { errors: string[] }) : JSX.Element {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">There were {errors.length} errors with your submission</h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc pl-5 space-y-1">
            {errors.map((error: string) => {
              return <li data-testid='form-error' key={error}>{error}</li>
            })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const Login : FC = () => {
  const [errors, setErrors] = useState([]);
  const [_token, setToken] = useState(null);
  const [formValues, setFormValues] = useState({ email: "", password: "" })
  const { login, authenticated } = useContext(AuthContext)
  const router = useRouter()

  const [loginUser] = useMutation(LOGIN_USER, {
    onCompleted: data => {
      setErrors(data.login.errors);
      setToken(data.login.token);

      if(data.login.token) {
        localStorage.setItem("token", data.login.token)
        setToCurrentVersion();
        login()
      }
      else {
        localStorage.removeItem("token")
      }
    }
  })

  useEffect(() => {
    if(authenticated) {
      router.push("/")
    }
  }, [authenticated, router]);

  const onSubmit = (event : React.FormEvent<HTMLFormElement>) : void => {
    event.preventDefault();
    loginUser({ variables: { email: formValues.email, password: formValues.password } })
  };

  return (
    <div data-testid='login-page'>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
          </div>
          {errors.length > 0 && <FormErrors errors={errors} />}
          <form className="mt-8 space-y-6" onSubmit={onSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={formValues.email}
                  onChange={e => setFormValues({ ...formValues, email: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={formValues.password}
                  onChange={e => setFormValues({ ...formValues, password: e.target.value })}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-primary-600 hover:text-primary-500">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-primary-500 group-hover:text-primary-400" aria-hidden="true" />
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
